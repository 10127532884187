import React,{useEffect} from'react';
import {
  meData,
  meSupportExperience,
  meIsAccountAdmin,
  meEntitlements,
  isProductFound,
  meUnifiedAdminFlag,
  meHideAdminSection,
  meHideBillingSection} from 'modules/me-module';
import QuickLinksView from '../components/quick-link-view';
import { useSelector } from 'react-redux';
import { meIsBillingAdmin,sterlingAccountStatusGet,sterlingAccountStatus, billingIsLoading } from 'modules/billing-module';
import { useDispatch } from 'react-redux';
import { getAccountKey, getLicenses,getBillingAdmin } from 'modules/extadmin-module';

export const QuickLinksContainer =() =>{
  const {
    supportExperience ,
    meData ,
    meIsAccountAdmin,
    meIsBillingAdmin,
    isProductFound,
    unifiedAdminFlag,
    hideAdminSection,
    hideBillingSection,
    sterlingAccountStatus,
    getAccountKey,
    getLicenses,
    getBillingAdmin,
    isLoading,
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();

  useEffect(()=>{
    //check if the user is sterling user and billing admin
    if(getBillingAdmin && getLicenses.length == 1 && getLicenses[0].channel == "offline" ){
      if(getAccountKey){
        dispatch(sterlingAccountStatusGet(getAccountKey));
      }
    }
  },[getAccountKey,getLicenses,getBillingAdmin,dispatch]);

  return(
    <QuickLinksView
      hideAdminSection={hideAdminSection}
      hideBillingSection={hideBillingSection}
      meData={meData}
      supportExperience={supportExperience}
      unifiedAdminFlag={unifiedAdminFlag}
      meIsAccountAdmin={meIsAccountAdmin}
      meIsBillingAdmin={meIsBillingAdmin}
      isProductFound={isProductFound}
      accountType={sterlingAccountStatus}
      isSterlingBillingAdmin={getBillingAdmin}
      isLoading={isLoading}
    />
  );
};

export const getValuesFromStore =(state) =>({
  meEntitlements: meEntitlements(state),
  meData: meData(state),
  supportExperience: meSupportExperience(state),
  meIsAccountAdmin: meIsAccountAdmin(state),
  meIsBillingAdmin: meIsBillingAdmin(state),
  isProductFound : isProductFound(state),
  unifiedAdminFlag: meUnifiedAdminFlag(state),
  hideAdminSection: meHideAdminSection(state),
  hideBillingSection: meHideBillingSection(state),
  sterlingAccountStatus: sterlingAccountStatus(state),
  getAccountKey: getAccountKey(state),
  getLicenses: getLicenses(state),
  getBillingAdmin: getBillingAdmin(state),
  isLoading: billingIsLoading(state),
});

export default QuickLinksContainer;