import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const billingUrl = { baseURL: environment.get().api.billingURL };
const sterlingUrl = { baseURL: environment.get().api.sterlingUrl };
const billingEndpoint = appconfig.endpoints.billingAccountMe;
const paymentEndpoint = appconfig.endpoints.paymentUrl;
const subscriptionEndpoint = appconfig.endpoints.subscriptionUrl;
const sterlingEndpoint = appconfig.endpoints.sterlingNetNew;

export const daoBillingMeGet = () => api.get(billingEndpoint, billingUrl);

export const daoSterlingNetNew = (accountKey) => api.get(sterlingEndpoint(accountKey), {
  ...sterlingUrl,
  headers: {
    "tenant": "MyAccount"
  }
});

export const daoSubscriptionMeGet = (key) => api.get(subscriptionEndpoint(key),billingUrl);

export const daoPaymentsMeGet = (key) => api.get(paymentEndpoint(key),billingUrl);

