import React from 'react';
import PropTypes from "prop-types";
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
} from "react-intl";
import classes from '../components/quick-link-view.scss';
import {
  Typography,Button,
  Skeleton
} from '@getgo/chameleon-web-react-wrapper';
import environment from 'lib/environment';
import {
  CashFilledIcon, PeopleOutlinedIcon, HelpCircleOutlinedIcon
} from '@getgo/chameleon-icons/react';
import { Box } from "@mui/material";

const helpBilling = environment.get().help.billing;
const helpCorpBilling = environment.get().help.corpBilling;
const helpAdmin = environment.get().help.admin;
const helpUnifiedAdmin = environment.get().help.unifiedAdmin;
const helpHandleByProductContact = environment.get().help.handledByProductFaq;
const helpContact = environment.get().help.faq;

const t = defineMessages({
  "quick-links.titleBillingAdmin": {
    id: "quick-links.titleBillingAdmin",
    defaultMessage:"Billing",
    description: "Billing",
  },
  "quick-links.not.billingAdmin.content": {
    id: "quick-links.not.billingAdmin.content",
    defaultMessage: "You need to be a billing admin to access the billing portal.",
    description: "Quick links billing card title for user who is not billing admin",
  },
  "quick-links.billingAdmin.link": {
    id: "quick-links.billingAdmin.link",
    defaultMessage: "Go to Billing",
    description: "Quick links Billing card Link title",
  },
  "quick-links.billingAdmin.content": {
    id: "quick-links.billingAdmin.content",
    defaultMessage: "View invoices, make payments, and add to your subscription from the billing portal.",
  },
  "quick-links.titleAccountAdmin": {
    id: "quick-links.titleAccountAdmin",
    defaultMessage: "Product administration",
  },
  "quick-links.contentAccountAdmin": {
    id: "quick-links.contentAccountAdmin",
    defaultMessage: "Manage users, admin settings, and product-specific settings.",
    description:"Quick links Admin center tile content for admin",
  },
  "quick-links.contentNonAccountAdmin": {
    id: "quick-links.contentNonAccountAdmin",
    defaultMessage: "Contact the Account Admin for access to the Admin Center.",
    description:"Quick links Admin center tile content for non admin",
  },
  "quick-links.linkAccountAdmin": {
    id: "quick-links.linkAccountAdmin",
    defaultMessage: "Go to admin",
    description:"Quick links Admin center tile link title",
  },
  "quick-links.helpTitle": {
    id: "quick-links.helpTitle",
    defaultMessage: "Get Support",
    description: "Quick links Help tile Title"
  },
  "quick-links.helpContent": {
    id: "quick-links.helpContent",
    defaultMessage: "Need help? View FAQ’s and find help articles on our support site. Or contact an agent for support.",
  },
  "quick-links.helpContactLink": {
    id: "quick-links.helpContactLink",
    defaultMessage:  "Explore our support site",
  },
});

const QuickLinksView = ({
  hideAdminSection,
  hideBillingSection,
  unifiedAdminFlag,
  supportExperience,
  meIsAccountAdmin,
  meIsBillingAdmin,
  isProductFound,
  accountType,
  isSterlingBillingAdmin,
  isLoading,
  }) =>{
  const isSterlingRedirection = accountType == "EXISTING" ;
  return (
    <Box data-section="my-account-product-container" className={classes["productContainer"]}>
      {
      hideBillingSection
        ? null
        : isProductFound
          ? <Box className={`${classes["quick-link-card"]}`} data-testid="quick-link-card">
            <section className={classes["quickLinkHeader"]} data-testid="quick-link-header">
              <Box className={classes["quickLinkBadgeIcon"]}>
                <Box className={classes["quickLinkCardIcon"]} data-testid="quick-link-card-title-icon">
                  <CashFilledIcon size="24px" />
                  <Typography variant="heading-small">
                    <FormattedMessage {...t["quick-links.titleBillingAdmin"]} />
                  </Typography>
                </Box>
              </Box>
              <Box className={classes['quickLinkContent']}>
                <Box>
                  <Typography variant="body-small">
                    <FormattedMessage {...t['quick-links.billingAdmin.content']} />
                  </Typography>
                </Box>
                {
                  (meIsBillingAdmin || isSterlingBillingAdmin)
                    ? null
                    : <Typography variant="body-small">
                      <FormattedMessage {...t['quick-links.not.billingAdmin.content']} />
                    </Typography>
                }
                {
                  isLoading? <Skeleton variant="rectangle" /> :
                  (meIsBillingAdmin || isSterlingBillingAdmin) ?
                  <Box className={classes["quick-card-link"]}>
                    <Button
                      variant="tertiary"
                      href={isSterlingRedirection ? helpCorpBilling :helpBilling }
                      onClick={() => { window.open( isSterlingRedirection ? helpCorpBilling :helpBilling ); }}
                    >
                      <FormattedMessage {...t['quick-links.billingAdmin.link']} />
                    </Button>
                  </Box>: null
                }
              </Box>
            </section>
          </Box>
        : null
    }
      {
      hideAdminSection
        ? null
        : <Box className={`${classes["quick-link-card"]}`} data-testid="quick-link-card">
          <section className={classes["quickLinkHeader"]} data-testid="help-card__header">
            <Box className={classes["quickLinkBadgeIcon"]}>
              <Box className={classes["quickLinkCardIcon"]} data-testid="subscription-card__product-icon">
                <PeopleOutlinedIcon size="24px" />
                <Typography variant="heading-small">
                  <FormattedMessage {...t["quick-links.titleAccountAdmin"]} />
                </Typography>
              </Box>
            </Box>
            <Box className={classes['quickLinkContent']}>
              {
                meIsAccountAdmin
                  ? <Typography variant="body-small">
                    <FormattedMessage {...t['quick-links.contentAccountAdmin']} />
                  </Typography>
                  : <Typography variant="body-small">
                    <FormattedMessage {...t['quick-links.contentNonAccountAdmin']} />
                  </Typography>
              }
              {meIsAccountAdmin
                ? <Box className={classes["quick-card-link"]}>
                  <Button variant="tertiary" onClick={() => {  window.open((unifiedAdminFlag ? helpUnifiedAdmin : helpAdmin)); }}>
                    <FormattedMessage {...t['quick-links.linkAccountAdmin']} />
                  </Button>
                </Box>
                : null}
            </Box>
          </section>
        </Box>
    }
      {
      supportExperience && supportExperience.limited
        ? null
        : <Box className={classes["quick-link-card"]} data-testid="quick-link-card">
          <section className={classes["quickLinkHeader"]} data-testid="help-card__header">
            <Box className={classes["quickLinkBadgeIcon"]}>
              <Box className={classes["quickLinkCardIcon"]} data-testid="subscription-card__product-icon">
                <HelpCircleOutlinedIcon size="24px" margin-right="10px" />
                <Typography variant="heading-small">
                  <FormattedMessage {...t['quick-links.helpTitle']} />
                </Typography>
              </Box>
            </Box>
            <Box className={classes['quickLinkContent']}>
              <Typography variant="body-small">
                <FormattedMessage {...t['quick-links.helpContent']} />
              </Typography>
              <Box className={classes["quick-card-link"]}>
                <Button
                  variant="tertiary"
                  onClick={() => {
                    window.open(supportExperience && supportExperience.handledByProduct ? helpHandleByProductContact : helpContact);
                  }}
                  >
                  <FormattedMessage {...t['quick-links.helpContactLink']} />
                </Button>
              </Box>
            </Box>
          </section>
        </Box>
    }
    </Box>

  );
};

QuickLinksView.propTypes= {
  hideAdminSection: PropTypes.bool.isRequired,
  hideBillingSection: PropTypes.bool.isRequired,
  unifiedAdminFlag: PropTypes.bool.isRequired,
  supportExperience: PropTypes.object.isRequired,
  isProductFound: PropTypes.bool.isRequired,
  meIsAccountAdmin: PropTypes.bool.isRequired,
  meIsBillingAdmin: PropTypes.bool.isRequired,
  accountType: PropTypes.string.isRequired,
  isSterlingBillingAdmin: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default injectIntl(QuickLinksView);