import {
  daoAdminUrlget,
} from 'dao/extadmin-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const EXT_ADMIN_ME_GET = 'EXT_ADMIN_ME_GET';
export const EXT_ADMIN_ME_GET_SUCCESS = 'EXT_ADMIN_ME_GET_SUCCESS';
export const EXT_ADMIN_ME_GET_FAILURE = 'EXT_ADMIN_ME_GET_FAILURE';

export const ADMIN_ME_GET = 'ADMIN_ME_GET';
export const ADMIN_ME_GET_SUCCESS = 'ADMIN_ME_GET_SUCCESS';
export const ADMIN_ME_GET_FAILURE = 'ADMIN_ME_GET_FAILURE';

export const ADMIN_ME_PUT = 'ADMIN_ME_PUT';
// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  invitationId: "",
  accountWithInvitation: null,
  invitationStatus: "",

  // generic or global properties go here
  isLoading: false,
  isActionLoading: false,
  adminData : {},
  accountKey: "",
  licenses: {},
};

// ------------------------------------
// Selectors
// ------------------------------------
export const eaIsLoading = (state) => state.extAdmin.isLoading;

export const eaAdminData = (state) => state.extAdmin.extAdminMe || null;

export const getAdminData = (state) => state.extAdmin.adminData;

export const getAccountKey = (state) => state.extAdmin.adminData.accountKey;

export const getLicenses = (state) =>
  state.extAdmin.adminData.accounts?.[0].licenses || {};

export const getBillingAdmin = (state) => {
  const adminRoles = state.extAdmin.adminData.adminRoles;
  return (adminRoles && adminRoles.includes('BILLING_ADMIN')) || false ;
};
// ------------------------------------
// Actions
// ------------------------------------
export const AdminMeReceiveGetSuccess = (payload = {}) => ({
  type: ADMIN_ME_GET_SUCCESS,
  payload,
});

export const AdminMeReceiveGetFailure = (payload = {}) => ({
  type: ADMIN_ME_GET_FAILURE,
  payload,
});

export const AdminMeRequestGet = () => (dispatch) => {
  dispatch({
    type: ADMIN_ME_GET,
  });

  return daoAdminUrlget()
    .then(
      response => dispatch(AdminMeReceiveGetSuccess(response.data)),
      ex => dispatch(AdminMeReceiveGetFailure(ex)),
    );
};


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [EXT_ADMIN_ME_GET]: (state) => ({...state, isLoading: true}),
  [EXT_ADMIN_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        extAdminMe: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [EXT_ADMIN_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ADMIN_ME_GET]: (state) => ({...state, isLoading: true}),
  [ADMIN_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object' || typeof action.payload === 'array') {
      return {
        ...state,
        adminData: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [ADMIN_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ADMIN_ME_PUT]: (state, action) => {
    if (typeof action.payload === 'object' || typeof action.payload === 'array') {
      return {
        ...state,
        adminData: action.payload,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const extAdminReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default extAdminReducer;
